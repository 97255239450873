import React from "react";
import { Grid, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const CustomDateTimePicker = ({ value, onChange, label, minDateTime, error, helperText }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item  xs={12} sm={6} md={6}>
        <DateTimePicker
          label={label}
          value={value ? dayjs(value) : null}
          onChange={(newValue) => {
            const formattedValue = newValue?.format("YYYY-MM-DDTHH:mm");
            onChange(formattedValue || "");
          }}
          minDateTime={dayjs(minDateTime)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              required
              error={error}
              helperText={helperText}
            />
          )}
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
