import { useState } from "react";
import apiService from "../axiosIntance";

const useCreateReminder = () => {

const [error, setError] = useState(null);
const [loading, setLoading] = useState(false);

    const postReminder = async (data) => {
    try {
      const response = await apiService.post("/reminder", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      const status = err.response?.status;
      const message = err.response?.data?.details || err.response?.data || err.message;
      const errorMessage = { message, status, error: (err?.response?.data?.error || err.message) };

      if ([400, 401, 403, 404, 500].includes(status)) {
        setError(err?.response?.data?.error || err.message);
        throw errorMessage;
      }

      setError({ message: "Failed to Create a Reminder.", status: "unknown" });
      throw { message: "Failed to Create a Reminder.", status: "unknown" };
    } finally {
      setLoading(false);
    }
}


    return { postReminder, error, loading };
}

export default useCreateReminder;