import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        fontFamily: '"Roboto", "Arial", sans-serif',
        borderTop: '1px solid #ddd',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: 'black',
          mb: 1,
        }}
      >
        &copy; Copyright {new Date().getFullYear()} WishoWish All rights reserved. Designed by
      </Typography>
      <Link
        href="https://pagatursoftware.com/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          fontWeight: 'bold',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
         Pagatur Software Pvt. Ltd
      </Link>
    </Box>
  );
};

export default Footer;
