import { useCallback, useState } from "react";
import apiService from "../../Api/axiosIntance";

const useGetAllDesignation = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDesignation = async (page, searchQuery) => {
    setLoading(true);
    setError(null);
    try {
      const res = await apiService.get(`/designations?page=${page}${searchQuery || ""}`);
      setResponse(res.data);
      return res.data;
    } catch (err) {
      setError(err?.response?.data || err?.message || "Error fetching Designations");
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, fetchDesignation };
};

export default useGetAllDesignation;
