import React , { useEffect , useState} from "react";
import { Box, Typography, Button } from "@mui/material";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignupSuccess = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(10); // Initial countdown value
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000); // Update every second
  
      const redirectTimer = setTimeout(() => {
        navigate('/login');
      }, 10000); // Redirect after 10 seconds
  
      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        textAlign: "center",
        background: "linear-gradient(to right, #e0f7fa, #80deea)",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#1565c0",
          mb: 2,
        }}
      >
        User Signup Successful!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You will be redirected to the login page in {countdown} seconds.
      </Typography>
      <Button
        variant="contained"
        size="100px"
        component="button"
        type="submit"
        sx={{
          backgroundColor: "#45a9cc",
          color: "#fff",
          fontWeight: "bold",
          textTransform: "none",
          mt: 2,
          borderRadius: "20px",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          "&:hover": {
            backgroundColor: "#35c7fa",
          },
        }}
        onClick={() => navigate("/login")}
      >
        Go to Login
      </Button>
    </Box>
  );
};

export default SignupSuccess;
