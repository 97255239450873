import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Store/AuthContext';
import { AlertProvider } from './Store/AlertContext';
import { UserProvider } from './Store/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider>
        <AlertProvider>
          <UserProvider>
          <App />
          </UserProvider>
        </AlertProvider>
      </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
