import { useState, useEffect } from "react";
import apiService from "../../Api/axiosIntance";
const useGetApi = (URL,trigger) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            setError(null); 
            try {
                const res = await apiService.get(URL);
                setResponse(res.data || {}); 
            } catch (err) {
                if (err.response) {
                    setError(`Response error: ${err.response.status} - ${err.response.data}`);
                } else if (err.request) {
                    setError("No response received from the server.");
                } else {
                    setError(`Request setup error: ${err.message}`);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [trigger]); 

    return { response, error, loading };
};

export default useGetApi;
