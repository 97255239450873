import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const capitalize = (string) => {
  return string
    .split('-') // Split the string into words by hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with a space
};

const BreadcrumbsComponent = () => {
  const location = useLocation();

  const pathnames = location.pathname
    .split('/')
    .filter((x) => x && isNaN(x)); 

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link component={RouterLink} to="/" underline="hover" color="inherit">
        Home
      </Link>

      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography color="textPrimary" key={to}>
            {capitalize(decodeURIComponent(value))}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            to={to}
            underline="hover"
            color="inherit"
            key={to}
          >
            {capitalize(decodeURIComponent(value))}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
