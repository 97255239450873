import React from 'react';
import { Grid, Typography } from '@mui/material';

const ErrorMessage = ({ error }) => {
  if (!error) return null; 

  return (
    <Grid
      item
      xs={12}
      style={{
        textAlign: 'center',
        padding: '10px',
        marginTop: '30px',
        backgroundColor: '#ffe6e6',
        borderRadius: '4px',
      }}
    >
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    </Grid>
  );
};

export default ErrorMessage;
