import { useState } from "react";
import apiService from "../axiosIntance";

export const useIsPhoneExist = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const isPhoneExist = async (data) => {
    setError(null);
    setLoading(true);
    try {
      const response = await apiService.get("/user/isPhoneNumberExist?phone=" + data);
      return response.data;
    } catch (err) {
      const status = err.response?.status || err.status;
      const message = err.response?.data || err.message;
      const errorMessage = { message, status };

      if ([400, 401, 403, 404, 500].includes(status)) {
        setError(errorMessage);
        throw errorMessage;
      }

      setError({ message: "Failed to Server.", status: "unknown" });
      throw { message: "Failed to Server.", status: "unknown" };
    } finally {
      setLoading(false);
    }
  };

  return { isPhoneExist, error, loading };
};
