import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  Autocomplete,
  MenuItem,
  Link,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../../Api/authApi/useSignUp";
import AlertMethod from "../../Custom/Alert/Alert";
import WhisLogo from "../../Asserts/Images/HeaderLogo.png";
import { useIsEmailExist } from "../../Api/authApi/useIsEmailExist";
import { countries } from "../../hooks/CountryCode/useCountryCodeApi";
import { useIsPhoneExist } from "../../Api/authApi/useIsPhoneExist";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "91",
    password: "",
    gender: "",
    dateOfBirth: "",
  });
  const [inputError, setInputError] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [step, setStep] = useState(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const { signup, loading, error: signupError } = useSignup();
  const {
    isEmailExist,
    error: isEmailExistError,
    loading: isEmailExistLoading,
  } = useIsEmailExist();
  const {
    isPhoneExist,
    error: isPhoneExistError,
    loading: isPhoneExistLoading,
  } = useIsPhoneExist();
  const navigate = useNavigate();

  const validationRules = {
    firstName: (value) =>
      !value.trim()
        ? "First Name is required"
        : /^[A-Za-z\s]+$/.test(value.trim())
          ? ""
          : "First Name must contain only alphabets and spaces",

    lastName: (value) =>
      !value.trim()
        ? "Last Name is required"
        : /^[A-Za-z\s]+$/.test(value.trim())
          ? ""
          : "Last Name must contain only alphabets and spaces",

    phoneNumber: async (value) => {
      if (!value) return "Phone number is required";
      if (!/^\d{10}$/.test(value)) return "Phone number must be in 10 digits";
      if (value.length !== 10) return "Phone number must be in 10 digits";

      try {
        const res = await isPhoneExist(value);
        return res.exists ? "Phone number already exists" : "";
      } catch (err) {
        return "Error checking phone number existence";
      }
    },
    email: async (value) => {
      if (!value) return "Email is required";
      if (!/^[a-zA-Z0-9._@]+$/.test(value)) return "Email is invalid";
      if (!/\S+@\S+\.\S+/.test(value)) return "Email is invalid";

      try {
        const res = await isEmailExist(value);
        if (!res.exists) {
          if (step >= 2 !== true) setStep(2);
        } else {
          setStep(1);
          return res.exists ? "Email already exists" : "";
        }
      } catch (err) {
        return "Error checking email existence";
      }
    },
    password: (value) =>
      !value.trim()
        ? "Password is required"
        : value.length >= 6
          ? ""
          : "Password must be greater than 6 characters",
    gender: (value) => (value.trim() ? "" : "Gender is required"),
    dateOfBirth: (value) => {
      if (!value || !value.trim()) {
        return "Date of birth is required";
      }

      const dob = new Date(value);
      const today = new Date();

      if (isNaN(dob.getTime())) {
        return "Invalid date format";
      }

      const dobYear = dob.getFullYear();
      const dobMonth = dob.getMonth();
      const dobDate = dob.getDate();

      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth();
      const todayDate = today.getDate();

      if (
        dobYear > todayYear ||
        (dobYear === todayYear && dobMonth > todayMonth) ||
        (dobYear === todayYear &&
          dobMonth === todayMonth &&
          dobDate >= todayDate)
      ) {
        return "Date of Birth must be earlier than today";
      }

      return "";
    },
  };

  useEffect(() => {
    const isValid =
      Object.values(formData).every((value) => value.trim()) &&
      Object.values(inputError).every((error) => !error);
    setIsFormValid(isValid);
  }, [formData, inputError]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (validationRules[name]) {
      const error = await validationRules[name](value);
      setInputError((prev) => ({ ...prev, [name]: error }));
    }

    updateStepAndClearError(name);
  };

  const updateStepAndClearError = (name) => {
    const fieldStepMap = {
      // email: 2,
      firstName: 3,
      lastName: 4,
      password: 6,
      dateOfBirth: 7,
    };

    if (step < fieldStepMap[name]) {
      setStep(fieldStepMap[name]);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") return;
    setAlertOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const combinedData = {
      ...formData,
      phoneNumber: `+${formData.countryCode}${formData.phoneNumber}`,
    };
  
    try {
      const response = await signup(combinedData);
      if (response) {
        setAlertMessage("User created successfully!");
        setAlertSeverity("success");
        setAlertOpen(true);
        navigate("/signup-success"); // Redirect to the new success page
      }
    } catch (err) {
      setAlertMessage(err.message || "Error creating user.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };
  

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setFormData((prev) => ({
        ...prev,
        countryCode: newValue.phone,
      }));
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(to right, #e0f7fa, #80deea)",
        width: "100vw",
        pb: 10,
        margin: 0,
        minHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          padding: { xs: 2, sm: 3, md: 4 },
          boxSizing: "border-box",
        }}
      >
        <Paper
          elevation={4}
          sx={{
            padding: 3,
            borderRadius: "16px",
            textAlign: "center",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            overflow: "auto",
            boxSizing: "border-box",
          }}
        >
          <Link href="/login" style={{ display: "inline-block" }}>
            <img
              src={WhisLogo}
              alt="Logo"
              style={{
                width: "200px",
                marginBottom: "16px",
                cursor: "pointer",
                filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))",
              }}
            />
          </Link>
          <Divider sx={{ my: { xs: 1, sm: 1.5 }, borderColor: "#ddd" }} />
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#1565c0",
              mb: { xs: 1.5, sm: 2 },
              fontSize: { xs: "22px", sm: "26px" },
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            Sign Up
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {step >= 1 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!inputError.email}
                    helperText={inputError.email}
                    autoFocus
                  />
                </Grid>
              )}

              {step >= 2 && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    error={!!inputError.firstName}
                    helperText={inputError.firstName}
                  />
                </Grid>
              )}

              {step >= 3 && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    error={!!inputError.lastName}
                    helperText={inputError.lastName}
                  />
                </Grid>
              )}

              {step >= 4 && (
                <>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) =>
                        `${option.label} (${option.phone})`
                      }
                      value={
                        countries.find(
                          (country) => country.phone === formData.countryCode
                        ) || countries.find((country) => country.phone === "91")
                      }
                      onChange={handleCountryChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Phone Number"
                      name="phoneNumber"
                      value={`${formData.countryCode} ${formData.phoneNumber}`}
                      onChange={async (e) => {
                        const inputValue = e.target.value
                          .replace(formData.countryCode, "")
                          .trim();

                        if (/^\d{0,10}$/.test(inputValue)) {
                          if (step <= 5) setStep(5);
                          setFormData((prev) => ({
                            ...prev,
                            phoneNumber: inputValue,
                          }));
                          const error =
                            await validationRules.phoneNumber(inputValue);
                          setInputError((prevErrors) => ({
                            ...prevErrors,
                            phoneNumber: error,
                          }));
                        }
                      }}
                      error={!!inputError.phoneNumber}
                      helperText={inputError.phoneNumber}
                    />
                  </Grid>
                </>
              )}

              {step >= 5 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Toggle between text and password
                    value={formData.password}
                    onChange={handleChange}
                    error={!!inputError.password}
                    helperText={inputError.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {step >= 6 && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Date of Birth"
                    name="dateOfBirth"
                    type="date"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    error={!!inputError.dateOfBirth}
                    helperText={inputError.dateOfBirth}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}

              {step >= 7 && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      label="Gender"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#45a9cc",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#35c7fa" },
                    py: 1.2,
                    borderRadius: "20px",
                    mt: 3,
                  }}
                  disabled={loading || !isFormValid}
                >
                  {loading ? "Signing Up..." : "Sign Up"}
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                <Typography variant="body2">
                  Already have an account?{" "}
                  <Link
                    href="/login"
                    style={{
                      textDecoration: "none",
                      color: "#1565c0",
                      fontWeight: "bold",
                    }}
                  >
                    Sign In
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default SignUp;
