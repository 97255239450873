import { useState } from "react";
import apiService from "../axiosIntance";

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const signup = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.post("/user/signup", data);
      return response.data;
    } catch (err) {
      const errorDetails = err.response?.data?.details;
      const status = err.response?.status;
      const message = errorDetails ||err.response?.data || err.message;
      const errorMessage = { message, status };

      if ([400, 401, 403, 404, 500].includes(status)) {
        setError(errorMessage);
        throw errorMessage;
      }

      setError({ message: "Failed to signup.", status: "unknown" });
      throw { message: "Failed to signup.", status: "unknown" };
    } finally {
      setLoading(false);
    }
  };

  return { signup, loading, error };
};
