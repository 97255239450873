import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  Divider,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useForgotPassword from "../../Api/authApi/useForgotPassword";
import AuthHeader from "../../Component/Header/AuthHeader";
import { useIsEmailExist } from "../../Api/authApi/useIsEmailExist";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isMailExist, setIsMailExist] = useState(false);

  const navigate = useNavigate();
  const { forgotPassword, error: forgotPasswordError, loading } = useForgotPassword();
  const { isEmailExist } = useIsEmailExist();

  const emailHandler = async (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setError("");

    if (!/\S+@\S+\.\S+/.test(emailValue)) {
      setError("Invalid email format");
      return;
    }

    try {
      const res = await isEmailExist(emailValue);
      setIsMailExist(res.exists);
      if (!res.exists) {
        setError("Email does not exist");
      }else{
        setError("Email is found");
      }
    } catch (err) {
      setError("Error checking email existence");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }

    try {
      const res = await forgotPassword(email);
      if (res.status === 200) {
        navigate("/reset-password");
      }
    } catch (err) {
      setError(err.message || "An error occurred while processing your request");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(to right, #e0f7fa, #80deea)",
        overflow: "hidden",
      }}
    >
      <AuthHeader />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: { xs: 2, sm: 3 },
        }}
      >
        <Grid item xs={11} sm={8} md={6} lg={4}>
          <Paper
            elevation={4}
            sx={{
              background: "rgba(255, 255, 255, 0.9)",
              p: 3,
              borderRadius: 4,
              boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
              border: "1px solid #ddd",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#1565c0",
                mb: { xs: 1.5, sm: 2 },
                fontSize: { xs: "22px", sm: "26px" },
              }}
            >
              Forget Password
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#606770",
                mb: { xs: 1.5, sm: 2 },
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Please enter your email address to receive an OTP code for your account.
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={emailHandler}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
                "& .MuiInputBase-root": {
                  fontSize: { xs: "14px", sm: "16px" },
                },
              }}
              color={isMailExist && "success"}
              error={!!error && !isMailExist}
              helperText={error}
            />

            <Divider sx={{ my: { xs: 1.5, sm: 2 }, borderColor: "#ddd" }} />

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#45a9cc",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#35c7fa" },
                    fontSize: { xs: "14px", sm: "16px" },
                    py: { xs: 1, sm: 1.2 },
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  disabled={loading || !isMailExist}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
