import { useState, useRef } from 'react';
import apiService from '../axiosIntance';

export const useUpdateProfile = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const abortController = useRef(null);

  const updateProfile = async (updatedData) => {
    setLoading(true);

    abortController.current = new AbortController();

    try {
      const res = await apiService.put(
        `/user/profile`,
        updatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: abortController.current.signal,
        }
      );
      setResponse(res.data);
      return res;
    } catch (err) {
      if (err.name === 'CanceledError') {
        throw err.name; 
      }
      const status = err.response?.status;
      const message = err.response?.data?.details || err.response?.data || err.message;
      const errorMessage = { message, status, error: (err?.response?.data?.error || err.message) };

      if ([400, 401, 403, 404, 500].includes(status)) {
        setError(err?.response?.data?.error || err.message);
        throw errorMessage;
      }
      if (status === 413) {
        setError({ message: "Image size is Too Large", status: "413" });
        throw { message: "Image size is Too Large", status: "413" };
      }

      setError({ message: "Failed to Update a Profile.", status: "unknown", error: "Failed to update a Profile." });
      throw { message: "Failed to update a Profile.", status: "unknown", error: "Failed to update a Profile." };
    } finally {
      setLoading(false);
    }
  };

  // Cancel the ongoing request
  const cancelRequest = () => {
    if (abortController.current) {
      abortController.current.abort();
    }
  };

  return { updateProfile, response, error, loading, cancelRequest };
};
