import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CustomModal from "../../Custom/CustomModal";
import ProfileUpdate from "./ProfileUpdate";
import useGetProfile from "../../Api/ProfileApi/useProfileGet";
import { useUser } from "../../Store/UserContext";

const Profile = () => {
  const [open, setOpen] = useState(false);
  const [reLoadProfoile, setReLoadProfile] = useState(false)

  const { user, setUser } = useUser();

  const userId = localStorage.getItem("userId");

  const { response, error, loading, fetchProfile } = useGetProfile(userId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile, reLoadProfoile]);

  useEffect(() => {
    if (response) {
      setUser(response);
    }
  }, [response]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: 900,
          background: "#FFFFFF",
          borderRadius: "16px",
          overflow: "hidden",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
          padding: { xs: "16px 12px 24px 12px", sm: 3, md: 4 },
        }}
      >
        {error ? <p>{error}</p> :(
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#2B6CB0",
                }}
              >
                Profile
              </Typography>
              <IconButton onClick={handleOpen}>
                <EditIcon sx={{ color: "#2B6CB0" }} />
              </IconButton>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>

                <Avatar
                  src={user?.photo}
                  alt={user?.firstName}
                  variant="rounded"
                  sx={{
                    width: 150,
                    height: 150,
                    margin: "0 auto",
                    boxShadow: "0 6px 15px rgba(0,0,0,0.3)",
                    borderRadius: "16px",
                    img: {
                      objectFit: "cover", 
                      objectPosition: "top", 
                      marginLeft: "-2px", 
                      marginRight: "-2px",
                      width: "152px",
                      height: "152px",
                    },
                  }}
                />

                <Typography
                  variant="h5"
                  sx={{ mt: 2, fontWeight: "bold", color: "#2B6CB0" }}
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#2B6CB0", mb: 2 }}
                  >
                    Personal Information
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      {
                        label: "Full Name",
                        value: `${user?.firstName} ${user?.lastName}`,
                      },
                      { label: "Username", value: user?.username },
                      { label: "Email", value: user?.email },
                      { label: "Phone", value: user?.phoneNumber },
                      { label: "Date Of Birth", value: user?.dateOfBirth },
                      // { label: "Location", value: user?.location },
                    ].map((info, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Typography
                          variant="subtitle2"
                          color="#718096"
                          sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                        >
                          {info.label}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "1rem", color: "#2D3748" }}
                        >
                          {info.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      <CustomModal open={open} onClose={handleClose}>
        <ProfileUpdate profile={user} closeUpdateModalHandler={handleClose} onReload={setReLoadProfile} />
      </CustomModal>
    </>
  );
};

export default Profile;
