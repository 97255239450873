import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  Chip,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomHeader from "../../Custom/TableHeader/CustomHeader";
import useGetAllShifts from "../../Api/ShiftApi/useGetAllShifts";
import useGetAllDesignation from "../../hooks/designation/useGetAllDesignation";
import useGetAllFrequency from "../../Api/ReminderFrequencyApi/useGetAllFrequency";
import useCreateReminder from "../../Api/ReminderApi/useCreateReminder";
import AlertMethod from "../../Custom/Alert/Alert";
import { countries } from "../../hooks/CountryCode/useCountryCodeApi";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDateTimePicker from "../../Custom/CustomDateTimePicker";
import { useAlert } from "../../Store/AlertContext";

const ReminderCreate = ({ onClose = () => { }, onReload = () => { } }) => {

  const [step, setStep] = useState({ current: 0 });

  const [page, setPage] = useState({
    shiftId: 1,
    designationId: 1
  })

  const [formData, setFormData] = useState({
    designationId: null,
    shiftId: null,
    reminderFrequencyIds: [],
    taskName: "",
    dateToComplete: null,
    toContactNumber: "",
    textMessage: "",
    photo: "",
    regardsText: "",
    countryCode: "91",
  });
  const [selectedOptions, setSelectedOptions] = useState(countries);

  const [designationList, setDesignationList] = useState({ data: [], metadata: {} });
  const [shiftList, setShiftList] = useState({ data: [], metadata: {} });

  const [inputError, setInputError] = useState({});
  const [error, setError] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState({})

  const showAlert = useAlert();

  const {
    postReminder,
    error: reminderError,
    loading: reminderLoading,
  } = useCreateReminder();
  const {
    response: shiftOptions,
    error: shiftError,
    loading: shiftLoading,
    fetchShifts,
  } = useGetAllShifts();
  const {
    response: designationOptions,
    error: designationError,
    loading: designationLoading,
    fetchDesignation,
  } = useGetAllDesignation();
  const {
    response: reminderFrequencyOptions,
    error: reminderFrequencyError,
    loading: reminderFrequencyLoading,
  } = useGetAllFrequency();

  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleChange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prev) => ({
        ...prev,
        photo: file.name,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }

    if (name === "taskName")
      step.current >= 4 || setStep({ ...step, current: 4 });
    if (name === "dateToComplete")
      step.current >= 6 || setStep({ ...step, current: 6 });
    if (name === "textMessage")
      step.current >= 7 || setStep({ ...step, current: 7 });
    if (name === "regardsText")
      step.current >= 8 || setStep({ ...step, current: 8 });
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setFormData((prev) => ({
        ...prev,
        countryCode: newValue.phone,
      }));
    }
  };

  const handleAutocompleteChange = (field) => (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      [field]: newValue ? newValue.id : null,
    }));
    if (field === "designationId") {
      step.current >= 1 || setStep({ ...step, current: 1 });
    }
    if (field === "shiftId")
      step.current >= 2 || setStep({ ...step, current: 2 });
  };
  const handleMultiSelectChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      reminderFrequencyIds: newValue.map((item) => item.id),
    }));
    if (step.current <= 3) setStep({ ...step, current: 3 });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const reminderSubmitHandler = async (e) => {
    e.preventDefault();
    console.log("this")

    const updateData = {
      ...formData,
      toContactNumber: `+${formData.countryCode}${formData.toContactNumber}`,
    };
    try {
      const response = await postReminder(updateData);
      if (response.status === 201) {
        setAlertSeverity("success");
        setAlertMessage(response.data || "Reminder created successfully!");
        setAlertOpen(true);
        if (location.pathname === "/") {
          navigate("/reminder");
        } else {
          setTimeout(() => {
            onClose();
            onReload((prev) => !prev);
          }, 1000);
        }
      }
    } catch (err) {
      setError(err);
      setAlertSeverity("error");
      setAlertMessage(
        err?.message?.details?.error ||
        err?.message?.error ||
        "Failed to create Reminder."
      );
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    if (designationList?.data && designationOptions?.data) {
      const updatedOptions = [...designationList.data, ...designationOptions.data];
      const uniqueOptions = updatedOptions.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id)
      );
      setDesignationList({ data: uniqueOptions });
    }
  }, [designationOptions, designationList]);

  useEffect(() => {
    if (shiftList?.data && shiftOptions?.data) {
      const updatedOptions = [...shiftList.data, ...shiftOptions.data];
      const uniqueOptions = updatedOptions.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id)
      );
      setShiftList({ data: uniqueOptions });
    }
  }, [shiftList, shiftOptions]);


  useEffect(() => {
    fetchDesignation(page.designationId, `&search=${searchQuery.designationId || ""}`);
  }, [page.designationId, searchQuery.designationId]);

  useEffect(() => {
    fetchShifts(page.shiftId, `&search=${searchQuery.shiftId || ""}`);
  }, [page.shiftId, searchQuery.shiftId]);

  const handleScroll = (name, event) => {
    const listboxNode = event.target;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight
    ) {
      if (name === "designationId") {
        if (designationOptions?.metadata.totalPages > page.designationId) {
          setPage({
            ...page,
            designationId: page.designationId + 1
          });
        }
      }

      if (name === "shiftId") {
        if (shiftOptions.metadata.totalPages > page.shiftId) {
          setPage({
            ...page,
            shiftId: page.shiftId + 1
          });
        }
      }
    }
  };


  const icons = [
    {
      tooltip: 'Cancel',
      icon: <CancelIcon />,
      color: 'error',
      onClick: location.pathname !== '/' ? onClose : () => {
        setFormData({
          taskName: '',
          dateToComplete: null,
          toContactNumber: '',
          textMessage: '',
          photo: "",
          regardsText: '',
          countryCode: '91',
          reminderFrequencyIds: []

        })
        setStep({ current: 0 })
      }
    },
    {
      tooltip: 'Save Reminder',
      icon: <CheckCircleIcon />,
      color: 'success',
      disabled: (step.current >= 8) ? false : true,
      type: 'submit',
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={reminderSubmitHandler}
      sx={{
        maxWidth: 1000,
        minWidth: 400, // Adjusted min-width
        margin: "20px auto", // Added margin for spacing
        padding: "20px", // Added padding for inner spacing
        borderRadius: "8px", // Added border-radius for rounded corners
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Added box shadow for depth
        justifyContent: "center",
        "@media (max-width: 1000px)": {
          maxWidth: 600,
        },
        "@media (max-width: 600px)": {
          minWidth: "auto",
          padding: "10px", // Reduced padding for smaller screens
        },
      }}
    >
      <CustomHeader title="Create Reminder" icons={icons} />
      <Grid container spacing={2} sx={{ padding: '20px 0' }}>
        <AlertMethod
          alertMessage={alertMessage}
          alertOpen={alertOpen}
          alertSeverity={alertSeverity}
          handleAlertClose={handleAlertClose}
        />

        {step.current >= 0 && (
          <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              name="designationId"
              options={designationList?.data || []}
              getOptionLabel={(option) => option.title}
              value={
                designationList?.data.find(
                  (option) => option.id === formData.designationId
                ) || null
              }
              onChange={handleAutocompleteChange("designationId")}
              onOpen={() => {
                if (designationOptions.data.length === 0) {
                  setPage({
                    ...page,
                    designationId: 1
                  });
                }
              }}
              ListboxProps={{
                onScroll: (e) => handleScroll("designationId", e),
              }}
              renderInput={(params) => (
                <TextField {...params} label="Events" required
                  onChange={e => {
                    setPage({
                      ...page,
                      designationId: 1
                    })
                    setSearchQuery({
                      ...searchQuery,
                      designationId: e.target.value
                    })
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {designationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
        )}

        {step.current >= 1 && (
          <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              options={shiftList?.data || []}
              getOptionLabel={(option) => option.shift_name}
              value={
                shiftList?.data?.find(
                  (option) => option.id === formData.shiftId
                ) || null
              }
              onChange={handleAutocompleteChange("shiftId")}
              ListboxProps={{
                onScroll: (e) => handleScroll("shiftId", e),
              }}
              onOpen={() => {
                if (shiftOptions.data.length === 0) {
                  setPage({
                    ...page,
                    shiftId: 1
                  });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Relationship" required
                  onChange={e => {
                    setPage({
                      ...page,
                      shiftId: 1
                    })
                    setSearchQuery({
                      ...searchQuery,
                      shiftId: e.target.value
                    })
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {shiftLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
        )}

        {step.current >= 2 && (
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              options={reminderFrequencyOptions || []}
              getOptionLabel={(option) => option.name}
              value={reminderFrequencyOptions?.filter((option) =>
                formData.reminderFrequencyIds.includes(option.id)
              )}
              onChange={handleMultiSelectChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      label={option.name}
                      {...tagProps}
                      sx={{
                        fontSize: '12px',
                        margin: '4px', // Adjusted margin for better spacing
                        height: '30px', // Adjusted height for better fit
                        backgroundColor: '#b2ebf2', // Added background color for chip
                        color: '#004d40', // Set text color for chip
                      }}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Frequency"
                  placeholder="Select Frequencies"
                />
              )}
              fullWidth
              sx={{
                // width: 500,
                ".MuiInputBase-root": {
                  fontSize:
                    formData.reminderFrequencyIds?.length > 1 ? "12px" : "14px",
                  // height: formData.reminderFrequencyIds.length > 1 ? 'auto' : '48px',
                },
              }}
            />
          </Grid>
        )}

        {step.current >= 3 && (
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Contact Name"
              name="taskName"
              value={formData.taskName}
              onChange={handleChange}
              fullWidth
              required

            />
          </Grid>
        )}

        {step.current >= 4 && (
          <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              options={selectedOptions}
              name="countryCode"
              getOptionLabel={(option) => `${option.label} (${option.phone})`}
              value={
                countries.find(
                  (country) => country.phone === formData.countryCode
                ) || countries.find((country) => country.phone === "91")
              }
              onChange={handleCountryChange}
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
              fullWidth
              required
            />
          </Grid>
        )}

        {step.current >= 4 && (
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Contact Number"
              name="toContactNumber"
              value={`${formData.countryCode} ${formData.toContactNumber}`}
              onChange={(e) => {
                const inputValue = e.target.value
                  .replace(formData.countryCode, "")
                  .trim();
                if (/^\d{0,10}$/.test(inputValue)) {
                  if (step.current <= 5) setStep({ ...step, current: 5 });
                  setFormData((prev) => ({
                    ...prev,
                    toContactNumber: inputValue,
                  }));
                }
              }}
              fullWidth
              required
              error={
                formData.toContactNumber &&
                formData.toContactNumber.length !== 10
              }
              helperText={
                formData.toContactNumber &&
                  formData.toContactNumber.length !== 10
                  ? "Phone number must be 10 digits long"
                  : ""
              }
            />
          </Grid>
        )}

        {step.current >= 5 && (
          <CustomDateTimePicker
            value={formData.dateToComplete}
            onChange={(newValue) => {
              setFormData((prev) => ({
                ...prev,
                dateToComplete: newValue,
              }));
              if (step.current <= 6) setStep({ ...step, current: 6 });
            }}
            label="Event Date"
            minDateTime={getCurrentDateTime()}
            error={
              formData.dateToComplete &&
              formData.dateToComplete < getCurrentDateTime()
            }
            helperText={
              formData.dateToComplete &&
                formData.dateToComplete < getCurrentDateTime()
                ? "Date must be greater than or equal to current date and time"
                : ""
            }
          />
        )}
        {step.current >= 6 && (
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Text Message"
              name="textMessage"
              value={formData.textMessage}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              required
            />
          </Grid>
        )}

        {step.current >= 7 && (
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Regards Text"
              name="regardsText"
              value={formData.regardsText}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
        )}

        {step.current >= 8 && (
         <Grid item xs={12} sm={12}>
         <Box
             display="flex"
             alignItems="center"
             justifyContent="space-between"
             sx={{
                 flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens
                 gap: 2, // Space between image and input
                 marginTop: 2,
             }}
         >
             <Box
                 display="flex"
                 flexDirection="column"
                 alignItems="flex-start"
                 sx={{ width: { xs: '100%', sm: '50%' } }}
             >
                 <Button
                     variant="outlined"
                     component="label"
                     fullWidth
                     sx={{ textAlign: 'center' }}
                 >
                     Choose File
                     <input
                         type="file"
                         name="photo"
                         hidden
                         accept="image/*"
                         onChange={(e) => {
                             const file = e.target.files[0];
                             const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/tiff', 'image/bmp', 'image/webp', 'image/psd'];
                             setInputError({ ...inputError, photo: "" });
                             if (file && !validImageTypes.includes(file.type)) {
                                 setInputError((prev) => ({ ...prev, photo: "Invalid file type. Please upload an image file (JPEG, PNG, GIF)." }));
                                 return;
                             }
                             setFormData((prev) => ({ ...prev, photo: file }));
                         }}
                     />
                 </Button>
                 {inputError.photo && (
                     <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                         {inputError.photo}
                     </Typography>
                 )}
             </Box>
             <Box
                 sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     width: { xs: '100%', sm: '50%' }, 
                     textAlign: 'center',
                 }}
             >
                 {formData.photo ? (
                     <img
                         src={
                             formData.photo instanceof File
                                  &&  URL.createObjectURL(formData.photo)
                                 
                         }
                         alt="Memorable"
                         width="200px"
                         height="200px"
                         style={{ borderRadius: '8px', border: '1px solid #ddd' }}
                     />
                 ) : (
                     <Typography variant="body2" color="textSecondary">
                         No Image Selected
                     </Typography>
                 )}
             </Box>
         </Box>
     </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReminderCreate;
