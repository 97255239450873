import axios from 'axios';
import { checkAndRemoveExpiredToken } from '../utils/checkAndRemoveExpiredToken';

const apiUrl = process.env.REACT_APP_API_URL;

const apiService = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to dynamically set the Authorization header
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    if (checkAndRemoveExpiredToken()) {
      window.location.href = '/login';
      throw new Error('Session expired');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Handle responses and errors
apiService.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default apiService;
