import { Alert, Box, Button, Card, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import AlertMethod from "../../Custom/Alert/Alert";
import { useNavigate } from "react-router-dom";
import useUserResetPassword from "../../Api/authApi/useResetPassword";
import AuthHeader from "../../Component/Header/AuthHeader";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const { userResetPassword, error: resetPasswordError, loading } = useUserResetPassword();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await userResetPassword({ password, otp });
      if (res.status === 200) {

        setAlertMessage("Your password was updated successfully");
        setAlertSeverity("success");
        setAlertOpen(true);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (err) {
      setError(err);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") return;
    setAlertOpen(false);
  };

  return (
    <>
      <AuthHeader />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 8,
          // alignItems: "center",
          // height: "100vh",
        }}
      >
        <Box
          sx={{
            minHeight: "100vh",
            minWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(to right, #e0f7fa, #80deea)",
            fontFamily: "Arial, sans-serif",
            overflow: "hidden", // Remove scrolling
            padding: 0,
            margin: 0,
            position: "relative",
          }}
        >
          <AlertMethod
            alertOpen={alertOpen}
            alertMessage={alertMessage}
            alertSeverity={alertSeverity}
            handleAlertClose={handleAlertClose} />
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pt: 0,
              px: { xs: 2, sm: 3 }, // Add some horizontal padding
            }}
          >
            <Grid item xs={11} sm={8} md={6} lg={4}>
              <Paper
                elevation={4}
                sx={{
                  background: "rgba(255, 255, 255, 0.9)",
                  p: 3,
                  borderRadius: 4,
                  boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#1565c0",
                    mb: { xs: 1.5, sm: 2 },
                    fontSize: { xs: "22px", sm: "26px" }, // Responsive font size
                    textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)", // Subtle text shadow
                  }}
                >
                  Reset Password
                </Typography>

                {/* Instructions */}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#606770",
                    mb: { xs: 1.5, sm: 2 },
                    fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                    textShadow: "1px 1px 3px rgba(0, 0, 0, 0.05)", // Subtle shadow
                  }}
                >
                  Please check your inbox for the OTP.
                </Typography>
                {
                  (error || resetPasswordError) && (
                    <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                      {resetPasswordError || error || "Something went wrong"}
                    </Alert>
                  )
                }
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="otp"
                  label="Email OTP"
                  name="otp"
                  autoFocus
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the field
                    },
                    "& .MuiInputBase-root": {
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust input font size
                    },
                  }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the field
                    },
                    "& .MuiInputBase-root": {
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust input font size
                    },
                  }}
                />
                <Divider sx={{ my: { xs: 1.5, sm: 2 }, borderColor: "#ddd" }} />

                {/* Action Buttons */}
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{ flexWrap: "wrap" }}
                >
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant="contained"
                      fullWidth
                      // component={RouterLink}
                      to="/"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#e4e6eb",
                        color: "#050505",
                        "&:hover": { backgroundColor: "#d8dadf" },
                        fontSize: { xs: "14px", sm: "16px" },
                        py: { xs: 1, sm: 1.2 }, // Padding for smaller screens
                        borderRadius: "20px", // Rounded corners
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Button shadow
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#45a9cc",
                        color: "#fff",
                        "&:hover": { backgroundColor: "#35c7fa" },
                        fontSize: { xs: "14px", sm: "16px" },
                        py: { xs: 1, sm: 1.2 }, // Padding for smaller screens
                        borderRadius: "20px", // Rounded corners
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Button shadow
                      }}
                    >
                      {loading ? "Loading..." : "Reset Password"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>

          {/* <AlertMethod
          alertOpen={alertOpen}
          alertMessage={alertMessage}
          alertSeverity={alertSeverity}
          handleAlertClose={handleAlertClose}
        />

        <Typography
          component="h1"
          variant="h5"
          textAlign="center"
          sx={{ fontWeight: 600, mb: 3 }}
        >
          Reset Password
        </Typography> */}



          {/* <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="Email OTP"
            name="otp"
            autoFocus
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            {loading ? "Loading..." : "Reset Password"}
          </Button>
        </Box> */}
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
