import React, { useState } from 'react';
import { TextField, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomHeader from '../../Custom/TableHeader/CustomHeader';
import { useUpdateProfile } from '../../Api/ProfileApi/useUpdateProfile';
import { useAlert } from '../../Store/AlertContext';
import ErrorMessage from '../../Component/Common/ErrorMessage';
import ImageUploadField from '../../Component/Common/ImageUploadField';
import PhoneInputField from '../../Component/Common/PhoneInputField';


const ProfileUpdate = ({ profile, closeUpdateModalHandler, onReload }) => {

  const [formData, setFormData] = useState({
    Username: profile?.Username || '',
    email: profile?.email || '',
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    dateOfBirth: profile?.dateOfBirth || '',
    gender: profile?.gender || '',
    phoneNumber: profile?.phoneNumber.slice(-10) || '',
    countryCode: profile?.countryCode || '91',
    location: profile?.location || '',
    photo: profile?.photo || null
  });
  const [responseError, setResponseError] = useState({});
  const [inputError, setInputError] = useState({});
  const showAlert = useAlert();
  const { updateProfile, response, error, loading, cancelRequest } = useUpdateProfile();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setResponseError({
      ...responseError,
      [name.charAt(0).toUpperCase() + name.slice(1)]: '',
    });
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleImageChange = (file, error) => {
    if (error) {
      setInputError({ photo: error });
    } else {
      setInputError({ photo: '' });
      setFormData((prev) => ({ ...prev, photo: file }));
    }
  };

  const cancelLoadingHandler = () => {
    cancelRequest();
    setTimeout(() => {
      closeUpdateModalHandler(false);
      onReload((prev) => !prev);
    }, 1000);
    showAlert('Profile update canceled', 'warning');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateProfileData = {
      ...formData,
      id: profile.id,
      phoneNumber: `+${formData.countryCode}${formData.phoneNumber}`,
    };

    try {
      const res = await updateProfile(updateProfileData);
      if (res.status === 200) {
        showAlert(res?.data || 'Profile Updated Successfully', 'success');
        setTimeout(() => {
          closeUpdateModalHandler(false);
          onReload((prev) => !prev);
        }, 1000);
      }
    } catch (err) {
      setResponseError(err?.message || 'Error Updating Profile.');
      if(err !== 'CanceledError') showAlert(err?.error || 'Error Updating Profile.', 'error');
    }
  };

  const icons = [

    {
      tooltip: "Cancel",
      icon: <CancelIcon />,
      color: "error",
      onClick: closeUpdateModalHandler,
    },
    {
      tooltip: "Update Profile",
      icon: <CheckCircleIcon />,
      color: "success",
      type: "submit"
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 1000,
        minWidth: 400,
        margin: '0 auto',
        padding: 2,
        '@media (max-width: 1000px)': {
          maxWidth: 600,
        },
        '@media (max-width: 600px)': {
          minWidth: 'auto',
        },
      }}
    >
      <CustomHeader title="Update Profile" icons={icons} />
      <Grid container spacing={3}>
        {error && (<ErrorMessage error={ "Failed to Update a Profile."} />)}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="Email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="dense"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            margin="dense"
            autoFocus
            error={!/^[A-Za-z\s]*$/.test(formData.firstName.trim()) || !formData.firstName.trim() || responseError?.FirstName}
            helperText={
              !formData.firstName.trim()
                ? "First Name is required"
                : /^[A-Za-z\s]+$/.test(formData.firstName.trim())
                  ? ""
                  : "First Name must contain only alphabets and spaces"
                  || responseError?.FirstName}
          />
        </Grid>


        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            margin="dense"
            error={!/^[A-Za-z\s]*$/.test(formData.lastName.trim()) || !formData.lastName.trim() || responseError?.LastName}
            helperText={
              !formData.lastName.trim()
                ? "Last Name is required"
                : /^[A-Za-z\s]+$/.test(formData.lastName.trim())
                  ? ""
                  : "Last Name must contain only alphabets and spaces"
                  || responseError?.LastName}
          />
        </Grid>

        <PhoneInputField
          formData={formData}
          setFormData={setFormData}
          responseError={responseError}
        />

        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="Location"
            name="location"
            value={formData?.location}
            onChange={handleChange}
            fullWidth
            margin="dense"
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Date of Birth"
            name="dateOfBirth"
            type="date"
            value={formData.dateOfBirth}
            onChange={handleChange}
            error={(formData.dateOfBirth && new Date(formData.dateOfBirth) >= new Date()) || responseError?.DateOfBirth}
            helperText={
              formData.dateOfBirth && new Date(formData.dateOfBirth) >= new Date()
                ? 'Date of Birth must be earlier than today'
                : ''
                || responseError?.DateOfBirth}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>


        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              label="Gender"
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <ImageUploadField
          label="Photo"
          value={formData.photo || ""}
          onChange={handleImageChange}
          error={inputError.photo}
        />


      </Grid>
    </Box>
  );
};

export default ProfileUpdate;
