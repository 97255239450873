import { useCallback, useState } from "react";
import apiService from "../axiosIntance";


const useGetProfile = (userId) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const fetchProfile = useCallback(async () => {
      setLoading(true);
      setError(null);
      try {
        const res = await apiService.get("/user/profile/" + userId);
        setResponse(res.data);
      } catch (err) {
        setError(err?.response?.data || err?.message || "Error fetching profile");
      } finally {
        setLoading(false);
      }
    }, []);
  
    return { response, error, loading, fetchProfile };
}

export default useGetProfile;