import React from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { countries } from '../../hooks/CountryCode/useCountryCodeApi';

const PhoneInputField = ({
  formData,
  setFormData,
  responseError,
  countryLabel = "Country",
  phoneLabel = "Contact Number",
  setStep,
  step
}) => {
  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setFormData((prev) => ({
        ...prev,
        countryCode: newValue.phone,
      }));
    }
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(formData.countryCode, '').trim();
    if (/^\d{0,10}$/.test(inputValue)) {
      if (step <= 5) setStep({ ...step, step: 5 });
      setFormData((prev) => ({
        ...prev,
        phoneNumber: inputValue,
      }));
    }
  };

  const countryValue =
    countries.find((country) => country.phone === formData.countryCode) ||
    countries.find((country) => country.phone === '91');

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={countries}
          name="countryCode"
          getOptionLabel={(option) => `${option.label} (${option.phone})`}
          value={countryValue}
          onChange={handleCountryChange}
          renderInput={(params) => <TextField {...params} label={countryLabel} />}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={phoneLabel}
          name="phoneNumber"
          value={`${formData.countryCode} ${formData.phoneNumber || ""}`}
          onChange={handlePhoneNumberChange}
          fullWidth
          required
          error={(formData.phoneNumber && formData.phoneNumber.length !== 10) || responseError?.PhoneNumber}
          helperText={
            formData.phoneNumber && formData.phoneNumber.length !== 10
              ? 'Phone number must be 10 digits long'
              : responseError?.PhoneNumber
          }
        />
      </Grid>
    </>
  );
};

export default PhoneInputField;
