import React, { useState } from 'react';
import { Grid, TextField, Box, FormHelperText } from '@mui/material';

const ImageUploadField = ({
  label = 'Upload Photo',
  value,
  onChange,
  error = '',
  helperText = 'Upload an image (JPEG, PNG, JPG).',
  previewStyle = { marginTop: '10px', borderRadius: '8px', maxWidth: '100%' },
}) => {
  const [preview, setPreview] = useState(
    value instanceof File ? URL.createObjectURL(value) : value
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/tiff', 'image/bmp', 'image/webp', 'image/psd'];
    if (file && file.type.startsWith('image/') && validImageTypes.includes(file.type)) {
      setPreview(URL.createObjectURL(file)); 
      onChange(file); 
    } else {
      onChange(null, 'Please upload a valid image file.');
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <TextField
        type="file"
        label={label}
        inputProps={{ accept: 'image/*' }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={handleFileChange}
        error={!!error}
        helperText={error || helperText}
      />
      {preview && (
        <Box style={previewStyle}>
          <img src={preview} alt="Preview" width="200px" height="200px" />
        </Box>
      )}
    </Grid>
  );
};

export default ImageUploadField;
