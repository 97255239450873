import { useState } from "react";
import apiService from "../axiosIntance";


export default function useUserResetPassword() {

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const userResetPassword = async (data) => {
        setLoading(true);
        try {
            const response = await apiService.post("/user/resetPassword", {code: data.otp, newPassword: data.password});
            return response;
        } catch (error) {
            const errorDetails = error.response?.data || error.message;
            setError(errorDetails);
            throw errorDetails;
        }finally {
            setLoading(false);
        }
    };
    return { userResetPassword, error, loading };
}