import React, { useEffect, useState } from "react";
import { IconButton, Avatar, Box, Menu, MenuItem, Divider, Link } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import HeaderLogo from "../../Asserts/Images/HeaderLogo.png";
import { useUser } from "../../Store/UserContext";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useGetProfile from "../../Api/ProfileApi/useProfileGet";

const Header = ({ toggleSidebar, toggleAuth }) => {
  const { user } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);

  const [image, setImage] = useState("")


  const userId = localStorage.getItem("userId");

  const { response, fetchProfile } = useGetProfile(userId);
  useEffect(() => {
    fetchProfile()
  }, [fetchProfile]);

  useEffect(() => {
    if (response) {
      setImage(response.photo);
    }
  }, [response]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const avatarUrl = user?.photo || null;

  return (
    <Box
      component="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        backgroundColor: "white",
        color: "black",
        position: "fixed",
        width: "100%",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton onClick={toggleSidebar} color="inherit">
          <MenuOpenIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
        <Link href="/">
          <img src={HeaderLogo} alt="Logo" style={{ width: "12rem", height: "5rem" }} />
        </Link>
      </Box>
      <div>
        <IconButton onClick={handleClick} color="primary">
          {(avatarUrl || image) ? <Avatar src={avatarUrl || image} alt={user?.name || "User Avatar"} sx={{ width: 40, height: 40,
             img: {
              objectFit: "cover", 
              objectPosition: "top", 
             }
           }} /> :  <AccountCircleIcon sx={{ fontSize: "2.5rem" }} />}
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link href="profile" style={{ textDecoration: "none", color: "inherit" }}>
              Profile
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <Link href="/login" style={{ textDecoration: "none", color: "inherit" }}>
              Sign Out
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

export default Header;
