import { AppBar, Toolbar, Button, Link, Box } from "@mui/material";
import WhisLogo from "../../Asserts/Images/HeaderLogo.png";

const AuthHeader = () => {
  return (

    <Box
    sx={{
      width: "100%",
      py: 2,
      px: 3,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", 
      background: "#e0f7fa",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      position: "fixed", 
      top: 0,
      left: 0,
      zIndex: 10,
    }}
  >
    <Link to="/" style={{ display: "inline-block" }}>
      <img
        src={WhisLogo}
        alt="WishWish Logo"
        style={{
          maxHeight: "55px",
          objectFit: "contain",
          cursor: "pointer",
        }}
      />
    </Link>
    <Button
          variant="outlined"
          sx={{ textTransform: "none", fontWeight: 600 }}
          component={Link}
          href="/login"
        >
          Login
        </Button>
  </Box>
   
  );
};

export default AuthHeader;
