import React, { Suspense, useEffect, useState } from 'react';
import {
    CssBaseline,
    Box,
    Drawer,
    Divider,
    Toolbar,
    Container,
    CircularProgress,
    Typography,
    Link
} from '@mui/material';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Header from '../Header/Header';
import NavItems from '../../NavItems';
import BreadcrumbsComponent from '../BreadCrumb/BreadcrumbsComponent';
import LoginForm from '../../Pages/Auth/SignIn';
import { checkAndRemoveExpiredToken } from '../../utils/checkAndRemoveExpiredToken';
import Footer from '../Footer/Footer';


const DashboardLayout = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const isAdmin = localStorage.getItem("admin");

    const isSmallScreen = useMediaQuery('(max-width:800px)');

    const toggleDrawer = () => setDrawerOpen(!drawerOpen);
    const toggleSidebar = () => setDrawerOpen(!drawerOpen);
    const toggleAuth = () => {
        if (token) {
            localStorage.clear();
            navigate("/login");
            setIsAuthenticated(false);
        }
    };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       const result = checkAndRemoveExpiredToken();
    //       if (result) {
    //         navigate("/login");
    //         setIsAuthenticated(false);
    //       }
    //     }, 3600000); // 1 hour 
      
    //     return () => clearInterval(interval); 
    //   }, []); 
      

    return (
        <>
            {token ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <CssBaseline />
                    <Header
                        toggleSidebar={toggleSidebar}
                        toggleAuth={toggleAuth}
                        isAuthenticated={isAuthenticated}
                        token={token}
                    />
                    <Box sx={{ display: 'flex', flex: 1 }}>
                        <Drawer
                            variant={isSmallScreen ? 'temporary' : 'persistent'}
                            open={drawerOpen}
                            onClose={isSmallScreen ? toggleDrawer : undefined}
                            ModalProps={{ keepMounted: true }}
                            defaultChecked={false}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    pt: 6,
                                    width: drawerOpen ? 240 : 0,
                                    boxSizing: 'border-box',
                                    bgcolor: '#2e70cd',
                                    color: '#fff',
                                    transition: 'width 0.4s ease',
                                    overflowX: 'hidden',
                                },
                            }}
                        >
                            <Toolbar />
                            <Divider />
                            <NavItems toggleDrawer={isSmallScreen ? toggleDrawer : null} />
                        </Drawer>

                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                pt: 4,
                                bgcolor: '#f4f4f4',
                                marginLeft: isSmallScreen ? 0 : `${drawerOpen ? 240 : 0}px`,
                                transition: 'margin-left 0.4s ease',
                                maxWidth: '100%',
                                overflowX: 'hidden',
                            }}
                        >
                            <Toolbar />
                            <Box sx={{ mt: 4, p: 2 }}>
                                <Box
                                    sx={{
                                        p: 1,
                                        width: '100%',
                                        bgcolor: 'white',
                                        borderRadius: 1,
                                        boxShadow: 1,
                                        textAlign: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <BreadcrumbsComponent />
                                </Box>

                                <Box
                                    sx={{
                                        p: { xs: 1, sm: 2 },
                                        bgcolor: 'white',
                                        borderRadius: 1,
                                        boxShadow: 1,
                                        overflow: 'auto', // Prevent overflow issues
                                        maxWidth: '100%',
                                    }}
                                >
                                    {token && isAdmin ? (
                                        <Suspense fallback={<CircularProgress />}>
                                            <Outlet />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/login" />
                                    )}
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    <Footer/>

                </Box>
            ) : (
                <LoginForm />
            )}
        </>
    );
};

export default DashboardLayout;
