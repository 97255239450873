import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Avatar,
  CssBaseline,
  Grid,
  Alert,
  Link,
  Divider,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router";
import { useAuth } from "../../Store/AuthContext";
import { useSignin } from "../../Api/authApi/useSignIn";
import { useVerifyEmail } from "../../Api/authApi/useVerifyEmail";
import CustomModal from "../../Custom/CustomModal";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomHeader from "../../Custom/TableHeader/CustomHeader";
import AlertMethod from "../../Custom/Alert/Alert";
// import WhisLogo from "../../Asserts/Images/WhisLogo.png";
import WhisLogo from "../../Asserts/Images/HeaderLogo.png";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [userResponse, setUserResponse] = useState({});

  const [error, setError] = useState(null);
  const [otpErr, setOtpError] = useState(null);

  const { login } = useAuth();
  const navigate = useNavigate();

  const [openModel, setOpenModel] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const [verifiedData, setVerifiedData] = useState({
    userId: null,
    code: "",
    type: "email",
  });
  const { verifyOtp, loading: otpLoading, error: otpError } = useVerifyEmail();
  const { signin, loading, error: signinError } = useSignin();

  const focusFirstField = useRef(null);

  useEffect(() => {
    if (focusFirstField.current) focusFirstField.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() && !password.trim()) {
      setError("Email and Password is required.");
      return;
    }
    if (!email.trim()) {
      setError("Email is required.");
      return;
    }

    if (!password.trim()) {
      setError("Password is required.");
      return;
    }

    try {
      const response = await signin({ email, password });
      setUserResponse(response);
      if (response?.Token) {
        const isAdmin = response?.Roles?.some((role) => role === "admin");
        const now = new Date().getTime();
        localStorage.setItem("userTimeExpiration", now);
        localStorage.setItem("userId", response?.UserID);
        login(response.Token, isAdmin);
        navigate("/");
        setError(null);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    const errorMessages = {
      404: "Resource not found.",
      401: "Unauthorized access. Please log in.",
      400: "Bad request. Please check your input.",
      500: "Internal server error. Please try again later.",
      403: "Forbidden. You do not have permission to access this resource.",
    };

    const defaultMessage = "Failed to login. Please try again.";

    if (err && err.status) {
      const message =
        err.message.message ||
        err.message ||
        errorMessages[err.status] ||
        defaultMessage;
      setError(message);

      if (err.status === 403) {
        setOpenModel(true);
        setVerifiedData({
          userId: err.message?.userId,
          code: "",
          type: "email",
        });
      }
    } else {
      setError(defaultMessage);
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const verifiedResponse = await verifyOtp(verifiedData);
      setAlertMessage("Email verified successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      setOpenModel(false);
      if (verifiedResponse?.Token) {
        const isAdmin = verifiedResponse?.Roles?.some(
          (role) => role === "admin"
        );
        const now = new Date().getTime();
        localStorage.setItem("userTimeExpiration", now);
        localStorage.setItem("userId", verifiedResponse?.UserID);
        login(verifiedResponse.Token, isAdmin);
        navigate("/");
      }
    } catch (err) {
      if (err.status === 404) {
        setOtpError(err.message);
      }
      if (err.status === 401) {
        setOtpError(err.message);
      }
      if (err.status === 400) {
        setOtpError(err.message);
      }
      if (err.status === 500) {
        setOtpError(err.message);
      }
      setOtpError("Failed to verify otp.");
      setAlertMessage("Failed to verify otp.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") return;
    setAlertOpen(false);
    if (alertSeverity === "success");
  };

  const icons = [
    {
      tooltip: "Cancel",
      icon: <CancelIcon />,
      color: "error",
      onClick: () => {
        setOpenModel(false);
        setOtpError(null);
        setError(null);
      },
    },
    {
      tooltip: otpLoading ? "Loading..." : "Verify OTP",
      icon: <CheckCircleIcon />,
      color: "success",
      type: "submit",
    },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh", // Full viewport height
          minWidth: "100vw", // Full viewport width
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(to right, #e0f7fa, #80deea)",
          fontFamily: "Arial, sans-serif",
          overflow: "auto", // Prevent content overflow
          padding: 0, // Ensure no extra padding
          margin: 0, // Remove margins
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
                px: { xs: 2, md: 4 },
              }}
            >
              <Box
                component="img"
                src={WhisLogo}
                alt="Wish O Wish Logo"
                sx={{
                  width: { xs: "60%", sm: "60%", md: "70%", lg: "70%" }, // Adjusted logo size for smaller screens
                  maxWidth: "100%", // Prevent overflow
                  mb: 2,
                  filter: "drop-shadow(0px 4px 6px rgba(0,0,0,0.2))",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={11} md={5}>
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.9)",
                p: { xs: 3, md: 4 },
                borderRadius: 4,
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              {error && (
                <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                  {error}
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                fullWidth
                component="button"
                type="submit"
                sx={{
                  backgroundColor: "#45a9cc",
                  color: "#fff",
                  fontWeight: "bold",
                  textTransform: "none",
                  mt: 2,
                  borderRadius: "20px",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                  "&:hover": {
                    backgroundColor: "#35c7fa",
                  },
                }}
              >
                {loading ? "loading..." : "Sign In"}
              </Button>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Link
                  href="/forget-password"
                  sx={{
                    display: "block",
                    mt: 2,
                    color: "#1565c0",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  Forgot password?
                </Link>
                <Link
                  href="/signup"
                  sx={{
                    display: "block",
                    mt: 2,
                    color: "#1565c0",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  No Account? Sign Up
                </Link>
              </Box>

              {/* <Divider sx={{ my: 3, borderColor: "#ddd" }} />

              <Button
                fullWidth
                sx={{
                  backgroundColor: "#45a9cc",
                  color: "#fff",
                  fontWeight: "bold",
                  textTransform: "none",
                  borderRadius: "20px",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                  "&:hover": {
                    backgroundColor: "#5e35b1",
                  },
                }}
              >
                <Link href="/signup" sx={{ color: "#fff", textDecoration: "none" }}>
                  Create new account
                </Link>
              </Button> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <CustomModal open={openModel} onClose={() => setOpenModel(false)}>
        <Box component="form" onSubmit={handleOtpVerification}>
          <CustomHeader title="Verify Email" icons={icons} />
          {otpErr ? (
            <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
              {otpErr}
            </Alert>
          ) : (
            <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
              Otp sent to your email
            </Alert>
          )}
          <Grid item xs={12} sm={6}>
            <AlertMethod
              open={alertOpen}
              alertMessage={alertMessage}
              alertSeverity={alertSeverity}
              handleAlertClose={handleAlertClose}
            />
            <TextField
              fullWidth
              label="Enter Email OTP"
              name="code"
              type="text"
              inputRef={focusFirstField}
              value={verifiedData.code || ""}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d{0,10}$/.test(inputValue)) {
                  setVerifiedData((prev) => ({
                    ...prev,
                    [e.target.name]: inputValue,
                  }));
                }
              }}
              error={!!verifiedData.code && verifiedData.code.length !== 4}
              helperText={
                !!verifiedData.code && verifiedData.code.length !== 4
                  ? "OTP must be 4 digits only"
                  : ""
              }
              margin="normal"
            />
          </Grid>
        </Box>
      </CustomModal>
    </>
  );
};

export default LoginForm;
