import React, { lazy } from 'react';
import { useAuth } from './Store/AuthContext';
import DashboardLayout from './Component/Layout/DefaultLayout';
import Profile from './View/Profile/Profile';

const Dashboard = lazy(() => import('./View/Dashboard/Dashboard'));
const UsersList = lazy(() => import('./View/User/UsersList'));
const RolesList = lazy(() => import('./View/Role/RolesList'));
const GetPermissionRoles = lazy(() => import('./View/Permission/GetRolePermission'));
const ShiftList = lazy(() => import('./View/Shift/ShiftList'));
const FrequencyList = lazy(() => import('./View/RemainderFrequency/FrequencyList'));
const DesignationList = lazy(() => import('./View/Designation/DesignationList'));
const UserProfile = lazy(() => import('./View/User/UserProfile'));
const AllReminderList = lazy(() => import('./View/Reminder/AllReminderList'));
const TodayRemindersList = lazy(() => import('./View/Reminder/TodayRemindersList'));
const RoleDetails = lazy(() => import('./View/Role/RoleDetails'));

const RoutesURL = () => {
    const { token, isAdmin } = useAuth(); 

    const baseRoutes = [
        {
            name: "Home",
            path: "/",
            element: <DashboardLayout />,
        },
        {
            name: "Dashboard",
            path: "/dashboard", 
            element: <Dashboard />,
        },
        {
            name: "Reminder",
            path: "/reminder",
            element: <AllReminderList />,
        },
        {
            name: "Today Reminder",
            path: "/today-reminder",
            element: <TodayRemindersList />,
        },
        {
            name: "Profile",
            path: "/profile",
            element: <Profile />,
        },
    ];

    const adminRoutes = [
        {
            name: "Role",
            path: "/role",
            element: <RolesList />,
        },
        {
            name: "Role Details",
            path: "/role/:roleId/details",
            element: <RoleDetails />,
        },
        {
            name: "User",
            path: "/user",
            element: <UsersList />,
        },
        {
            name: "User Details",
            path: "/user/:userId/details",
            element: <UserProfile />,
        },
        {
            name: "Permission",
            path: "/permission",
            element: <GetPermissionRoles />,
        },
        {
            name: "Relationships",
            path: "/relationships",
            element: <ShiftList />,
        },
        {
            name: "Frequency",
            path: "/frequency",
            element: <FrequencyList />,
        },
        {
            name: "Events",
            path: "/events",
            element: <DesignationList />,
        },
       
    ];
    return token && isAdmin !== "false" ? [...baseRoutes, ...adminRoutes] : baseRoutes;
};

export default RoutesURL;
