import React from "react";
import ReminderCreate from "../Reminder/ReminderCreate";
import { Box, Container, Typography, Button } from "@mui/material";

const Dashboard = () => {
    const [createModal, setCreateModal] = React.useState(false);
    const closeModalHandler = () => {
        setCreateModal(false);
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50vh",
                padding: 4,
                backgroundColor: "background.default",
                textAlign: "center",
            }}
        >
            {/* Welcome Text */}
            <Typography
                variant="h3"
                component="h1"
                sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    marginBottom: 2,
                }}
            >
                Welcome to Wish'O'Wish
            </Typography>

            <Typography
                variant="h6"
                color="text.secondary"
                sx={{
                    marginBottom: 4,
                    maxWidth: 600,
                }}
            >
                Create and manage all the important memorable dates of your friends, family, and colleagues in one place.
            </Typography>

            <Box
                sx={{
                    width: "100%",
                    maxWidth: 800,
                    padding: 4,
                    borderRadius: 4,
                    backgroundColor: "background.paper",
                    boxShadow: 3,
                    overflow: "hidden",
                }}
            >
                <ReminderCreate onClose={closeModalHandler} />
            </Box>
        </Container>
    );
};

export default Dashboard;
