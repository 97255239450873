import { useState } from "react";
import apiService from "../axiosIntance";

const useForgotPassword = () => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const forgotPassword = async (email) => {
        setLoading(true);
        try {
            const res = await apiService.post("/user/forgotPassword", { email });
            return res;
        } catch (err) {
            const errorDetails = err.response?.data || err.message
            setError(errorDetails);
            throw errorDetails;
        } finally {
            setLoading(false);
        }
    };

    return { forgotPassword, error, loading };
};

export default useForgotPassword;